<template>
  <!--   FORMULARIO -->
  <section class="container">
    <h2 class="text-center">Formulario de Consulta</h2>

    <div v-if="numeroConsulta" class="row mb-4 text-center mt-4">
      <div class="col-lg-12">
        <div class="alert alert-info" role="alert">
          ¡Gracias por hacer tu consulta! <br />
          Tu número de consulta es:
          {{ numeroConsulta }} <br />
          Recibirá un mail desde <b>tramite@sanjuan.gov.ar</b> con información
          para ayudarle y/o orientarle en la gestión de su consulta.
        </div>
      </div>
    </div>
    <div v-if="numeroConsulta == null" class="m-4">
      <div class="row mb-4 text-center mt-4">
        <div class="col-lg-12">
          <div class="alert alert-info" role="alert">
            Por favor complete el formulario a continuación.
          </div>
        </div>
      </div>
      <h4>Mesa de Consultas a Gobierno</h4>
      <form @submit.prevent="enviarFormulario">
        <div class="row justify-content-center">
          <div class="form-group col-12 mb-3">
            <label>Apellido y Nombre:</label>
            <input
              class="form-control"
              v-model="apellido"
              type="text"
              data-rule-required="true"
              aria-required="true"
              required
            />
            <p v-if="!apellidoValido" class="error-message">
              Por favor ingrese su Apellido y Nombre.
            </p>
          </div>
          <div class="form-group col-12 mb-3">
            <label>E-Mail:</label>
            <input
              class="form-control"
              v-model="email"
              type="email"
              data-rule-email="true"
              required
            />
            <p v-if="!emailValido" class="error-message">
              Por favor ingrese un correo electrónico válido.
            </p>
          </div>
          <div class="col-12 form-group mb-3">
            <label>Consulta:</label>
            <textarea
              class="form-text col-12 border rounded-5 p-4"
              v-model="mensaje"
              rows="12"
              data-rule-required="true"
              aria-required="true"
              required
            ></textarea>
            <p v-if="!consultaValida" class="error-message">
              Por favor ingrese su Consulta.
            </p>
          </div>
          <div class="col-12 form-group mb-3">
            <label>Teléfono</label>
            <input
              class="form-control"
              v-model="telefono"
              type="number"
              data-rule-required="true"
              aria-required="true"
              required
            />
            <p v-if="!telefonoValido" class="error-message">
              Por favor ingrese un número de teléfono válido.
            </p>
          </div>
          <button
            class="btn btn-secondary text-uppercase m-3 col-4"
            type="submit"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "FormComponent",
  data() {
    return {
      hash: "FC",
      items: null,
      title: null,
      links: null,
      urlServicio: "",
      telefono: "",
      email: "",
      apellido: "",
      mensaje: "",
      numeroConsulta: null,
      apellidoValido: true,
      emailValido: true,
      consultaValida: true,
      telefonoValido: true,
    };
  },
  methods: {
    enviarFormulario() {
      this.resetErrores();
      if (!this.validarCampos()) {
        return;
      }
      let apellido = this.apellido;
      let telefono = this.telefono;
      let email = this.email;
      let mensaje = `${this.mensaje} - Desde el sitio: ${window.location.origin}`;
      let urlServicio = `https://sanjuan.gob.ar/ol/?or=B37192BB31334445B548D5B82CF88B2B&Apellido=${apellido}&email=${email}&Consulta=${mensaje}&Telefono=${telefono}`;
      axios
        .get(urlServicio)
        .then((response) => {
          this.numeroConsulta = response.data.res[0]["Consulta N° "];
        })
        .catch((error) => {
          console.error("Error al enviar el formulario:", error);
        });
    },
    validarCampos() {
      let camposValidos = true;
      if (!this.apellido || !/^[A-Za-z]+(\s[A-Za-z]+)*$/.test(this.apellido)) {
        this.apellidoValido = false;
        camposValidos = false;
      }

      if (
        !this.email ||
        !this.validarEmail(this.email) ||
        this.email.trim() === ""
      ) {
        this.emailValido = false;
        camposValidos = false;
      }

      if (!this.mensaje) {
        this.mensajeValida = false;
        camposValidos = false;
      }
      const telefonoString = String(this.telefono).trim();
      if (
        !telefonoString ||
        telefonoString === "" ||
        !/^\d+$/.test(telefonoString)
      ) {
        this.telefonoValido = false;
        camposValidos = false;
      }

      return camposValidos;
    },
    validarEmail(email) {
      // Expresión regular para validar el formato de correo electrónico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    resetErrores() {
      this.apellidoValido = true;
      this.emailValido = true;
      this.mensajeValida = true;
      this.telefonoValido = true;
    },
  },
};
</script>
<style>
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
</style>
