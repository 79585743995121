<template>
  <section class="video-section">
    <div class="container video-container">
      <div class="video-title">
        <img src="@/assets/img/cidi/video-icon.svg" alt="">
        <span class="video-text">Tutorial Asistente virtual</span>
      </div>
      <div class="video-wrapper">
        <iframe src="https://sj.sanjuan.gob.ar/video_sj_cidi.mp4" frameborder="0"></iframe>
      </div>
    </div>
  </section>
</template>

<style>
  .video-section {
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .video-container {
    border-radius: 10px;
    overflow: hidden; /* Para que el padding del contenedor no afecte al video */
  }

  .video-title {
    display: flex;
    justify-content: center;
    background-color: #FF8300;
    padding: 27px;
    border-radius: 10px 10px 0 0;
  }

  .video-text {
    color: #fff;
    font-weight: bold;
    margin-left: 5px;
    font-size: 25px;
  }

  .video-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* Proporción 16:9 */
  }

  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>