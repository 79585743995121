<script>
export default {
  name: "InfoCidiComponent",
  props: {},
};
</script>

<template>
  <section class="info-cidi-section">
    <div class="container info-cidi-card">
      <img
        class="info-cidi-soy"
        src="@/assets/img/cidi/bot-soyCidi.svg"
        alt=""
      />
      <div class="info-cidi-title">
        <span class="info-cidi-text"
          >Accede directo con este QR<br />
          a whatsapp y solicitá tu turno<br />
          de salud online o escribinos<br />
          directo al</span
        >
        <span class="info-cidi-number"> 264 459 2201 </span>
      </div>
      <img
        class="info-cidi-qr"
        src="@/assets/img/cidi/qr-bot-cidi.svg"
        alt=""
      />
      <div class="info-cidi-title-mobile">
      <span class="info-cidi-text"
        >Accede directo con este QR
        a whatsapp y solicitá tu turno
        de salud online o escribinos
        directo al</span
      >
      <span class="info-cidi-number"> 264 459 2201 </span>
    </div>
    </div>
  </section>
</template>

<style scoped>
section{
  margin-top: 0px;
}

.info-cidi-title-mobile {
  display: none;
}

.info-cidi-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ff8300;
  border-radius: 10px;
  padding: 29px 50px !important;
  gap: 40px;
}

.info-cidi-text {
  color: #fff;
  font-weight: bold;
  font-size: 2rem;
  font-style: italic !important;
}

.info-cidi-number {
  color: #fff;
  font-weight: bold;
  font-size: 2rem;
  font-style: italic !important;
}

.info-cidi-number {
  color: #222;
}

@media screen and (max-width: 1200px) {
  .info-cidi-card {
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 30px !important;
  }
  .info-cidi-title-mobile {
    display: block;
    width: 60%;
    text-align: center;
  }
  .info-cidi-title {
    display: none;
  }
  .info-cidi-card-mobile {
    display: flex;
  }
  .info-cidi-text {
    font-size: 29px;
  }
  .info-cidi-number {
    font-size: 29px;
  }
  .info-cidi-qr {
    width: 30%;
  }
}

@media screen and (max-width: 992px) {
  .info-cidi-card {
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 10px !important;
  }
  .info-cidi-title-mobile {
    display: block;
    width: 80%;
    text-align: center;
  }
  .info-cidi-text {
    font-size: 20px;
  }
  .info-cidi-number {
    font-size: 20px;
  }
  .info-cidi-qr {
    width: 30%;
  }
}

@media screen and (max-width: 505px) {
  .info-cidi-card {
    padding: 50px 50px !important;
  }
  .info-cidi-text {
    font-size: 20px;
  }
  .info-cidi-number {
    font-size: 20px;
  }
  .info-cidi-qr {
    width: 80%;
  }
  .info-cidi-soy {
    width: 90%;
  }
}
</style>