<template>
    <section>
        <div class="container">
            <h2 class="pb-4">{{ titulo != null ? titulo : this.title != null ? this.title : '' }}</h2>
            <div class="row row-cols-1 g-4">
                <div class="col" v-for="(el,index) in items" :key="index">
                    <div class="card">
                        <div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column">
                            <div
                                class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3">
                                <svg class="ptn-i-documento text-ptn-color-gsj-2" v-if="!el.icon">
                                    <use xlink:href="#ptn-i-documento"></use>
                                </svg>
                                <svg class="ptn-i-documento text-ptn-color-gsj-2" v-if="el.icon">
                                    <use v-bind="{'xlink:href' : '#' + el.icon }"></use>
                                </svg>
                            </div>
                            <p class="card-text m-md-0" style="min-width: 90px;" v-if="showFecha">{{ el.fecha }}</p>
                            <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase">
                                <a v-bind:href="el.url" v-bind:target="el.target? el.target : '_self'" class="stretched-link">{{ el.title }}</a>
                            </h3>

                        </div>
                    </div>
                </div>
                <div class="col" v-if="cargando">
                    <div class="card loading-card-load" style="min-height:3px">
                    </div>
                </div>
            </div>
            <div class="py-4" v-if="showMore">
                <p><button ref="showMoreButton" class="btn btn-secondary text-uppercase" v-bind:disabled="nomore" v-on:click="getdata(this.current_page++)">Ver más</button></p>
            </div>
        </div>
    </section>
</template>





<script>
const axios = require("axios");
export default {
    name: "DocumentListComponent",
    props: {
        titulo: String,
        filtros : Object,
        data : Object,
        resultados : Number,
        showFecha : {
            type : Boolean,
            default: true
        }

    },
    data() {
        return {
            hash: "DLC",
            items: null,
            title : null,
            desc : null,
            current_page : 1,
            results : 3,
            cargando : false,
            nomore : false,
            showMore : true
        };
    },
    methods : {
        getdata : function () {
            if(this.data == null){
                let params = {};
                if(this.filtros != null && this.filtros != ""){
                    params = this.filtros;
                }
                params.tipo = this.hash;
                params.current_page = this.current_page;
                params.results = this.results;
                this.cargando = true;
                axios.post(process.env.VUE_APP_API_URL,params)
                .then(response => {
                    this.cargando = false;
                    if(this.items == null){
                        this.items = response.data ? response.data.items : null
                        if(response.data.total_items <= this.results){
                            this.showMore = false;
                        }
                    }else{
                        if(response.data){
                            this.items = this.items.concat(response.data.items);
                            if(this.items.length == response.data.total_items){
                                this.nomore = true;
                            }
                        }
                    }
                    response.data.items = this.items;                    
                    this.title = response.data ? response.data.title : null
                    this.desc = response.data ? response.data.desc : null
                    localStorage.setItem(this.hash, JSON.stringify(response));
                    if(this.current_page != 1){
                        this.$refs.showMoreButton.scrollIntoView({ behavior: "smooth", block: "center" })
                    }
                })
                .catch (error => {
                    if (!error.response) {
                        console.log("Fuera de Linea");
                        if(localStorage.getItem(this.hash)){
                            console.log("Cargando elementos de cache para " + this.hash);
                            var resp = JSON.parse(localStorage.getItem(this.hash));
                            this.items = resp.data ? resp.data.items : null                    
                            this.title = resp.data ? resp.data.title : null
                            this.desc = resp.data ? resp.data.desc : null
                            this.nomore = true;
                        }
                    }
                })
            }else{
                this.items = this.data;
            }
        }

    },
    mounted() {
        if(this.resultados){
            this.results = this.resultados;
        }
        this.getdata();
    },
};
</script>